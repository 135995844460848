import React from "react";
import styled from "styled-components";
import Logo from "./Logo";
import { FaTwitter, FaTelegram, FaInstagram, FaDiscord, FaExchangeAlt } from 'react-icons/fa';

const Footer = () => {
  return (
    <FooterSection>
      <FooterContent>
        <MainSection>
          <LogoSection>
            <Logo />
            <SocialLinks>
              <SocialIcon href="https://twitter.com/SkinnyBobMeme" target="_blank" rel="noopener noreferrer">
                <FaTwitter />
              </SocialIcon>
              <SocialIcon href="https://t.me/skinnybob4u" target="_blank" rel="noopener noreferrer">
                <FaTelegram />
              </SocialIcon>
              <SocialIcon href="https://www.instagram.com/skinnybobmeme/" target="_blank" rel="noopener noreferrer">
                <FaInstagram />
              </SocialIcon>
              <SocialIcon href="https://discord.gg/skinnybob" target="_blank" rel="noopener noreferrer">
                <FaDiscord />
              </SocialIcon>
              <SocialIcon href="https://app.uniswap.org/#/swap?outputCurrency=0x1a936953C6C5f832032C2A3CE40dA1C0934860a6" target="_blank" rel="noopener noreferrer">
                <FaExchangeAlt />
              </SocialIcon>
            </SocialLinks>
          </LogoSection>
          <NavSection>
            <NavColumn>
              <NavTitle>About</NavTitle>
              <NavItem href="#about">About Us</NavItem>
              <NavItem href="#team">Team</NavItem>
              <NavItem href="#roadmap">Roadmap</NavItem>
            </NavColumn>
            <NavColumn>
              <NavTitle>Token</NavTitle>
              <NavItem href="#tokenomics">Tokenomics</NavItem>
              <NavItem href="#howtobuy">How to Buy</NavItem>
              <NavItem href="#showcase">NFTs</NavItem>
            </NavColumn>
            <NavColumn>
              <NavTitle>Resources</NavTitle>
              <NavItem href="#faqs">FAQs</NavItem>
              <NavItem href="https://skinnybob.net" target="_blank">Whitepaper</NavItem>
              <NavItem href="https://github.com/skinnybob" target="_blank">Github</NavItem>
            </NavColumn>
          </NavSection>
        </MainSection>
        <Disclaimer>
          $SKIBOB is a meme cryptocurrency with no intrinsic value or expectation of financial return. 
          The token is community-led and for entertainment purposes only. 
          Always conduct your own research before trading cryptocurrencies.
        </Disclaimer>
      </FooterContent>
      <BottomBar>
        <Copyright>&copy; {new Date().getFullYear()} Skinny BOB. All Rights Reserved.</Copyright>
        <Credits>
          Made with 💖 by {" "}
          <a href="https://twitter.com/skinnybobmeme" target="_blank" rel="noopener noreferrer">
            Skinny BoB Team
          </a>
        </Credits>
      </BottomBar>
    </FooterSection>
  );
};

const FooterSection = styled.footer`
  background: linear-gradient(135deg, ${props => props.theme.body} 0%, ${props => props.theme.gradientEnd} 100%);
  color: ${props => props.theme.text};
  padding: 4rem 0 0;
  position: relative;
`;

const FooterContent = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 2rem;
`;

const MainSection = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 2rem;
`;

const LogoSection = styled.div`
  flex: 1;
  min-width: 200px;
`;

const SocialLinks = styled.div`
  display: flex;
  gap: 1rem;
  margin-top: 1rem;
`;

const SocialIcon = styled.a`
  color: ${props => props.theme.text};
  font-size: 1.8rem;
  transition: transform 0.3s ease, color 0.3s ease;

  &:hover {
    color: ${props => props.theme.accent};
    transform: scale(1.2);
  }
`;

const NavSection = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
  flex: 2;
`;

const NavColumn = styled.div`
  min-width: 150px;
`;

const NavTitle = styled.h3`
  font-size: 1.4rem;
  margin-bottom: 1rem;
  color: ${props => props.theme.accent};
  font-weight: 600;
`;

const NavItem = styled.a`
  display: block;
  color: ${props => props.theme.text};
  text-decoration: none;
  margin-bottom: 0.7rem;
  font-size: 1rem;
  transition: color 0.3s ease;

  &:hover {
    color: ${props => props.theme.accent};
  }
`;

const Disclaimer = styled.p`
  margin: 2rem 0;
  text-align: center;
  font-size: 0.95rem;
  color: ${props => props.theme.textLight};
  line-height: 1.6;
`;

const BottomBar = styled.div`
  background-color: ${props => props.theme.cardBg};
  padding: 1rem 0;
  text-align: center;
  margin-top: 2rem;
`;

const Copyright = styled.p`
  font-size: 0.9rem;
  color: ${props => props.theme.textLight};
`;

const Credits = styled.p`
  font-size: 0.9rem;
  color: ${props => props.theme.textLight};
  margin-top: 0.5rem;

  a {
    color: ${props => props.theme.accent};
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
`;

export default Footer;
