import React from "react";
import { ThemeProvider } from "styled-components";
import { Helmet } from "react-helmet";
import GlobalStyles from "./styles/GlobalStyles";
import { lightTheme, darkTheme } from "./styles/Themes";
import { useDarkMode } from "./components/useDarkMode";
import 'bootstrap/dist/css/bootstrap.min.css';

import Navbar from "./components/Navbar";
import Home from "./components/sections/Home";
import About from "./components/sections/About";
import Howtobuy from "./components/sections/Howtobuy";
import Tokenomics from "./components/sections/Tokenomics";
import Roadmap from "./components/sections/Roadmap";
import Showcase from "./components/sections/Showcase";
import BoredAlienPirate from "./components/sections/BoredAlienPirate";
import Team from "./components/sections/Team";
import Faqs from "./components/sections/Faqs";
import FooterBanner from "./components/FooterBanner";
import Footer from "./components/Footer";
import ScrollToTop from "./components/ScrollToTop";

function App() {
  const [theme, toggleTheme, mountedComponent] = useDarkMode();
  const themeMode = theme === 'light' ? lightTheme : darkTheme;

  const structuredData = {
    "@context": "https://schema.org",
    "@type": "Organization",
    "name": "Skinny Bob MemeCoin",
    "description": "An intergalactic crypto adventure featuring multi-chain support, NFTs, and a vibrant community.",
    "url": "https://skinnybob.net",
    "logo": "https://skinnybob.net/logo.png",
    "sameAs": [
      "https://twitter.com/SkinnyBobMeme",
      "https://t.me/skinnybob4u",
      "https://www.instagram.com/skinnybobmeme/"
    ],
    "potentialAction": {
      "@type": "BuyAction",
      "target": "https://app.uniswap.org/#/swap?outputCurrency=0x1a936953C6C5f832032C2A3CE40dA1C0934860a6&chain=mainnet"
    }
  };

  if(!mountedComponent) return <div/>

  return (
    <>
      <Helmet>
        <title>Skinny Bob MemeCoin - Intergalactic Crypto Adventure</title>
        <meta name="description" content="Join the Skinny Bob MemeCoin revolution! Explore our multi-chain token inspired by alien lore, featuring NFTs, staking, and a thriving community." />
        <meta name="keywords" content="Skinny Bob, MemeCoin, Cryptocurrency, NFT, Ethereum, Binance Smart Chain, Polygon, Solana" />
        <meta name="author" content="Skinny Bob Team" />
        <meta property="og:title" content="Skinny Bob MemeCoin - Intergalactic Crypto Adventure" />
        <meta property="og:description" content="Explore the Skinny Bob MemeCoin ecosystem featuring multi-chain support, NFTs, and a vibrant community." />
        <meta property="og:image" content="https://www.skinnybob.net/static/media/logo.3e1de824e89b9d361f70.webp" />
        <meta property="og:url" content="https://www.skinnybob.net" />
        <meta name="twitter:card" content="summary_large_image" />
        <link rel="canonical" href="https://skinnybob.net" />
        <script type="application/ld+json">
          {JSON.stringify(structuredData)}
        </script>
      </Helmet>
      <ThemeProvider theme={themeMode}>
        <GlobalStyles />
        <Navbar themeToggler={toggleTheme} />
        <Home />
        <About />
        <Howtobuy />
        <Tokenomics />
        <Roadmap />
        <Showcase />
        <BoredAlienPirate />
        <Faqs />
        <Team />
        <FooterBanner />
        <Footer />
        <ScrollToTop />
      </ThemeProvider>
    </>
  );
}

export default App;